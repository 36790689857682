import React, {useEffect} from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import '../stylesheets/index.less'
import {createStore, applyMiddleware, compose} from 'redux'
import {Provider} from 'react-redux'
import {AppContainer} from 'react-hot-loader'
import thunk from 'redux-thunk'
import {BrowserRouter} from 'react-router-dom'
import {reducers} from './reducers'
import {setAjaxDefaultError} from "startlibs";
import {useIntl} from "react-intl";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

let store = createStore((s) => (s))

const reportActionsToUrl = (reportUid, json) => ({
  'saveCompleteReport': ['PUT', 'report-json/'],
  'saveSettings': ['POST', 'report-settings/'],
  'saveSignature': ['POST', 'report-signatures/'],
  'finishReport': ['POST', 'finish-report/' + reportUid],
  'unfinishReport': ['POST', 'unfinish-report/' + reportUid],
  'saveTemplate': ['PUT', 'report-templates/'],
  'changeTemplate': ['PUT', 'change-report-template/' + reportUid],
  'renameTemplate': ['PUT', 'report-templates/rename/'],
  'deleteTemplate': ['DELETE', 'report-templates/' + json.templateId, ({templateId}) => ({id: templateId})],
  'deleteSignature': ['DELETE', 'report-signatures/' + json.signatureId, () => '']
})

export function init(props = {}) {

  store = createStore(reducers, props, composeEnhancers(applyMiddleware(thunk)))

  const getCommandUrl = (action, commandType, json) => {
    if (!reportActionsToUrl('', json)[action]) {
      console.log(action)
      return ['GET', '/']
    }
    const [method, url, fn] = reportActionsToUrl(store.getState().report.reportUID, json)[action]
    return [method, '/pasapi/' + url, fn ? fn(json) : json]
  }

  // if (process.env.NODE_ENV === 'development') {
  //   import(`./reducers/dev-polyfill/fakePostFetcher`).then(module =>
  //     setFetcher((action, json, commandType) => module.fakePostFetcher(action, getCommandUrl(action, commandType), json))
  //   )
  // } else {
  /*setFetcher((action, json, commandType, opts) => {
    const [method, url, formattedJson] = getCommandUrl(action, commandType, json)
    return fetch(url, {
      method: method,
      credentials: 'same-origin',
      headers: {'Content-Type': 'application/json; charset=UTF-8'},
      body: JSON.stringify(formattedJson),
      ...opts
    }).then(e => e.status >= 400 ? Promise.reject(e.json()) : e.text())
      .catch(e => e.then ? e.then(e => Promise.reject(e.errors ? e : {errorResponse: e})) : Promise.reject(e))
      .then(e => e && typeof e === 'string' ? JSON.parse(e) : e)
  })*/
  // }


  const render = Component => {
    ReactDOM.render(
      <AppContainer>
        <Provider store={store}>
          <BrowserRouter>
            <Component/>
          </BrowserRouter>
        </Provider>
      </AppContainer>,
      document.getElementById('root')
    )
  }

  render(App)
}

init()
