import React from 'react'
import styled from 'styled-components'
import {Link, withRouter} from 'react-router-dom'
import {withToggles} from 'startlibs/lib/hocs'
import {Errors, Field, Form, SimpleCheckbox, TextInput, withForm} from 'startlibs/lib/form'
import {getFetcher, postFetcher} from 'startlibs'
import connect from 'react-redux/es/connect/connect'
import {formFetchWithAuthorization, registerWithJwt, signIn} from '../reducers'
import {buildValidation, confirmEmails, confirmPasswords, getErrorForField, responseFailure} from '../lib/validation'
import {Button, SplitColumnsContainer} from 'startlibs/lib/components'
import {
  SignInLayout,
  Card,
  CardHeader,
  FieldRequirements,
  BelowFieldDescription,
  AdditionalInfo,
  CardActionButton
} from '../components/SigninLayout'
import {loginFailure} from './LoginForm'
import {PasswordInput} from '../components/PasswordInput'
import {ActivationSuccess} from './ActivationSuccess'
import jwt_decode from 'jwt-decode'
import {JwtLinkExpiredError, LinkExpiredError} from '../errors/LinkExpiredError'
import {FormattedMessage, injectIntl} from "react-intl";


export const allRequired = (intl) => (s, k) => !s && intl.formatMessage({
  defaultMessage: 'All fields are required.',
  description: 'Validation error message for required fields'
})

const TextInputMaybeLocked = ({value, form, path, ...rest}) =>
  value
    ? <TextInput
      {...rest}
      value={value}
      disabled
      locked
    />
    : <TextInput
      {...rest}
      tabIndex={1}
      form={form}
      path={path}
    />

@injectIntl
@withRouter
@withToggles('success', 'loading', 'visiblePassword','expiration')
@withForm(registerWithJwt)
@connect(undefined, {signIn})
export class JwtRegistrationForm extends React.Component {

  onFailure = (_,response,n,errors) => {
    if (response.response.status === 401) {
      this.props.expiration.open()
      return
    }
    return responseFailure(
      ({detail, title, type, message, fieldErrors}) =>
        ((message === 'error.validation' || message === 'error.http.401') && Array.isArray(fieldErrors) && fieldErrors.reduce((acc, {field}) => ({...acc, ...getErrorForField(this.props.intl)(field)}), {})) ||
        (title === 'passwordCannotBeEqualToThePreviousPasswords' && {password: [this.props.intl.formatMessage({
            defaultMessage: `Your new password must be different from your previous {pwdInHistory} passwords.`,
            description: 'JWT Password cannot be equal to the previous ones'
          },
          {
            pwdInHistory: this.props.system.systemAuthPolicy.pwdInHistory
          }
          )]}) ||
        (type === 'https://pas.purview.net/problem/invalid-password' && {password: []}) ||
        (message === 'error.emailexists' && {email: [this.props.intl.formatMessage({
            defaultMessage: 'This email is already registered',
            description: 'Registration error message for already registered email'
          })]})
    )(_,response,n,errors)
  }

  onSuccess = (_, {id_token}) => {
    this.props.loading.open()
    window.location = this.props.system.systemWebHooks.systemAuthWebHook + id_token
  }



  handleSubmit = (e) => {
    e.preventDefault()
    this.props.form.utils.submitProperties()
  }

  parsedJwt = jwt_decode(this.props.jwt)

  render() {
    const {intl, expired, system, form, success, jwt, visiblePassword, loading, expiration} = this.props

    const preValidation = buildValidation({
      password: [allRequired(intl), confirmPasswords(intl,'newPassword')]
    })
    const {sub: email} = this.parsedJwt
    if (expired || expiration.isOpen) {
      const loginInfo = JSON.parse(this.parsedJwt.loginInfo)
      return <JwtLinkExpiredError system={system} signinUrl={loginInfo.url} />
    }

    return <SignInLayout system={system}>
      <Card>
        <CardHeader>
          <h1><FormattedMessage
            defaultMessage="Password registration"
            description="JWT Registration page title"
          /></h1>
          <p><FormattedMessage
            defaultMessage="Please create a password to complete your account."
            description="JWT Registration page instructions"
          /></p>
        </CardHeader>
        <Form
          alwaysSave
          preValidation={preValidation}
          onSuccess={this.onSuccess}
          onFailure={this.onFailure}
          onSubmit={this.handleSubmit}
          form={form}
          jwt={jwt}
        >
          <TextInput
            label={intl.formatMessage({
              defaultMessage: "Email",
              description: "JWT Registration, email field label"
            })}
            value={email}
            locked
            disabled
          />
          <SplitColumnsContainer>
            <Field label={intl.formatMessage({
                defaultMessage: "Create password",
                description: "JWT Registration, password field label"
              })}>
              <PasswordInput
                tabIndex={1}
                form={form}
                path="password"
                syncVisible={visiblePassword}
                withoutField
              />
            </Field>
            <Field label={intl.formatMessage({
                defaultMessage: "Confirm new password",
              description: "JWT Registration, confirm password field label"
              })}>
              <PasswordInput
                tabIndex={1}
                onPaste={(e) => e.preventDefault()}
                form={form}
                path="newPassword"
                syncVisible={visiblePassword}
                withoutField
              />
            </Field>
          </SplitColumnsContainer>
          <FieldRequirements hasErrors={!!form.errors['password']}>
            <FormattedMessage
              defaultMessage="Your new password must be <b>{pwdMinimumLength} or more characters</b>, containing at least <b>one uppercase letter</b>, one <b>number</b> and one <b>special character</b>"
              description="Password requirements"
              values={{
                pwdMinimumLength: system.systemAuthPolicy.pwdMinimumLength,
                b: msg => <b>{msg}</b>
              }}
              />
          </FieldRequirements>
          <Errors form={form}/>
          <CardActionButton
            isLoading={form.isLoading || loading.isOpen}
            className="highlight with-loader"
            tabIndex={1}
            type="submit"
            highlight
          >
            <FormattedMessage
              defaultMessage="Register password"
              description="JWT Registration, submit button"
            />
          </CardActionButton>
        </Form>
      </Card>
    </SignInLayout>
  }
}
