import React from 'react'
import {Card, SignInLayout, CardHeader, CardActionButton, FieldRequirements} from '../components/SigninLayout'
import {TextInput, WithForm, Errors} from 'startlibs/lib/form'
import {withToggles} from 'startlibs/lib/hocs'
import {formFetch} from 'startlibs'
import {Button} from 'startlibs/lib/components'
import {buildValidation, confirmPasswords, minLength, required, responseFailure} from '../lib/validation'
import {PasswordInput} from '../components/PasswordInput'
import {FormattedMessage, useIntl} from "react-intl";

// http://localhost:9000/redefine-password?key=58365593372806686968&systemId=startlabs



const onSuccess = (system, keepLoading, autoLogin, successMsg) => (_, {id_token}) => {
  keepLoading.open()
  if(autoLogin === 'false'){
    successMsg.open()
  }else{
    window.location = system.systemWebHooks.systemAuthWebHook + id_token
  }
}

export const RedefinePasswordForm = withToggles('keepLoading','visiblePassword','successMsg')(({system, visiblePassword, header, tokenKey, keepLoading, autoLogin, successMsg}) => {
  const intl = useIntl()
  const preValidate = (key) => buildValidation({
      confirmPassword: confirmPasswords(intl,'password'),
      password: [required(intl), minLength(intl,8)]
    },
    ({password}) => ({key: key, password})
  )

  const onFailure = responseFailure(
    ({detail, message, title, type, status}) =>
      (title === 'passwordCannotBeEqualToThePreviousPasswords' && {password: [intl.formatMessage({
          defaultMessage: `Your new password must be different from your previous {pwdInHistory} passwords.`,
          description: 'Redefine password, repeated password error'},
          {
            pwdInHistory: system.systemAuthPolicy.pwdInHistory
          })]}) ||
      (type === 'https://pas.purview.net/problem/invalid-password' && {password: []}) ||
      (message === "error.keyExpired" && {'': [<span><FormattedMessage
          defaultMessage="This page has expired. Please <a>request a new recovery link</a> and try again."
          description="Redefine password, expired page error"
          values={{
            a: (chunks) => <a href={"/recovery?systemId="+system.systemId}>{chunks}</a>
          }}
        /></span>]})
  )

  return <Card>
    {header && header()}
    <WithForm
      action={formFetch}
      url="/pasapi/account/reset-password/finish"
      onFailure={onFailure}
      onSuccess={onSuccess(system, keepLoading, autoLogin, successMsg)}
      preValidation={preValidate(tokenKey)}
    >{(form) =>
      <form onSubmit={form.utils.handleSubmit}>
        <PasswordInput
          mandatory
          syncVisible={visiblePassword}
          path="password"
          label={intl.formatMessage({
            defaultMessage: "New password",
            description: "Redefine password, new password label"
          })}
        />
        <PasswordInput
          mandatory
          syncVisible={visiblePassword}
          path="confirmPassword"
          label={intl.formatMessage({
            defaultMessage: "Confirm password",
            description: "Redefine password, confirm password label"
          })}
        />
        <FieldRequirements hasErrors={!!form.errors['password']}>
        <FormattedMessage
          defaultMessage="Your new password must be <b>{pwdMinimumLength} or more characters</b>, containing at least <b>one uppercase letter</b>, one <b>number</b> and one <b>special character</b>"
          description="Password requirements"
          values={{
            pwdMinimumLength: system.systemAuthPolicy.pwdMinimumLength,
            b: msg => <b>{msg}</b>
          }}
        />
        </FieldRequirements>
        <Errors form={form}/>
        {!successMsg.isOpen && <CardActionButton highlight isLoading={form.isLoading || keepLoading.isOpen} type="submit">
          {autoLogin === "false" ? intl.formatMessage({
            defaultMessage: "Save",
            description: "Redefine password, save button"
          }): intl.formatMessage({
            defaultMessage:"Save and sign in",
            description: "Redefine password, save and sign in button"
          })}
        </CardActionButton>
        }
        {
          successMsg.isOpen &&
          <ul className="successContainer">
            <li>
              <span><FormattedMessage
                defaultMessage="Success! Your password has been updated."
                description="Redefine password, success message"
              /></span>
              {/* <span className="icon icon-x" onClick={() => { successMsg.close(); }}/> */}
            </li>
          </ul>
        }
      </form>
    }
    </WithForm>
  </Card>
})


export const RedefinePassword = (props) =>
  <SignInLayout system={props.system}>
    <RedefinePasswordForm
      {...props}
      tokenKey={new URLSearchParams(window.location.search).get('key')}
      header={() =>
        <CardHeader>
          <h1><FormattedMessage
            defaultMessage="Define new password"
            description="Redefine password title"
          /></h1>
          <p><FormattedMessage
            defaultMessage="Enter and confirm your new password below"
            description="Redefine password instructions"
          /></p>
        </CardHeader>
      }
    />
  </SignInLayout>
