import _ from 'lodash/fp'
export const getLanguageInfo = (locale, providerInfo, partial) =>
  _.flow(
    _.get('systemWebHooks.preferredLanguage.options'),
    _.find(o =>
      partial
        ? o.id.includes(locale)
        : o.id == locale
    )
  )(providerInfo)

export const getDefaultLanguageInfo = (providerInfo) =>
  _.flow(
    _.get('systemWebHooks.preferredLanguage.options'),
    _.find(o => o.id === _.get('systemWebHooks.preferredLanguage.defaultLanguage',providerInfo))
  )(providerInfo)

export const getInitLocale = (system) => {
  if (_.get('systemWebHooks.preferredLanguage.options.length',system) < 2) {
    return;
  }
  // const providerInfo =  {}
  const params = new URLSearchParams(window.location.search)
  const urlLocale = params.get("locale")
  if (urlLocale) {
    const languageInfo = getLanguageInfo(urlLocale, system)
    if (languageInfo) {
      return languageInfo.id
    }
  }
  const browserLang = getLanguageInfo(navigator.language, system, true)
  if (browserLang) {
    return browserLang.id
  }
  const defaultLanguage = getDefaultLanguageInfo(system)
  return defaultLanguage.id
}