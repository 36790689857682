import React from 'react'
import styled, {css} from 'styled-components'
import {getColor} from 'startlibs/lib/lib'
import {Button, Icon} from 'startlibs/lib/components'
import {SupportMessage} from './SupportMessage'
import {FormattedMessage} from "react-intl";
import {LocaleSelector} from "./LocaleSelector";

const SignInPageWrapper = styled.div `
  display: flex;
  min-height: 100%;
  flex-direction: column;
`

const SignInPageContent = styled.div `
  margin: auto;
  padding: 3rem 1rem 0;
  max-width: 46rem;
  width: 100%;
  .password-link {
    float: right;
    vertical-align: bottom;
    color: rgba(0,0,0,0.5);
    line-height: 19px;
    text-decoration: underline;
    &.has-error {
      color: ${getColor('alert')};
    }
  }
  .sending-link {
    float: right;
    vertical-align: bottom;
    color: ${getColor('main')};
    line-height: 19px;
    font-weight: 600;
  }
`

const SystemLogo = styled.div `
  margin: 0 auto 3rem;
  text-align: center;
  div {
    position: relative;
    display: inline-block;
    :before {
      content: '';
      pointer-events: none;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0,0,0,0.05);
    }
  }
  img {
    background: white;
    max-width: 320px;
    max-height: 180px;
    margin: 0 auto;
    @media (max-width:400px) {
      max-width: 240px;
    }
  }
`

export const Card = styled.div `
  padding: 2.5rem;
  background: white;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  p {
    font-size: 14px;
  }
  ${props => props.centralizedContent && `
    text-align: center;
  `}
`
export const CardActionButton = styled(Button)`
  min-width: 12rem;
  margin: 1.5rem auto 0;
  display: block
`
export const CardHeader = styled.div `
  margin-bottom: 1.5rem;
  h1 {
    color: ${getColor('main')};
    font-size: 22px;
    margin-bottom: 0.75rem;
  }
  p {
    color: rgba(0,0,0,0.5);
    font-size: 14px;
  }
`
export const AdditionalInfo = styled.div `
  font-size: 12px;
  color: rgba(0,0,0,0.5);
`

export const AdditionalActionButtons = styled.div `
  padding-top: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const AdditionalActionText = styled.span `
  margin-right: 1rem;
  font-size: 13px;
  color: rgba(0,0,0,0.5);
`

const SignInFooter = styled.div `
  text-align: center;
  color: rgba(0,0,0,0.4);
  padding: 2rem 0 1.5rem;
  a {
    font-size: 13px;
    color: rgba(0,0,0,0.4);
    font-weight: 600;
    margin-bottom: 0.25rem;
    display: inline-block;
    :hover {
      color: rgba(0,0,0,0.6);
    }
  }
  p {
    margin-bottom: 0;
  }
`
export const FieldRequirements = styled.div `
  background: rgba(0,0,0,0.05);
  color: rgba(0,0,0,0.5);
  font-size: 11px;
  padding: 0.75rem;
  border-radius: 5px;
  ${props => props.hasErrors && css`
    background: #ffdedf;
    color: ${getColor('alert')};
  `}
`
export const BelowFieldDescription = styled.div `
  color: rgba(0,0,0,0.5);
  font-size: 11px;
  margin-bottom: 1rem;
  margin-top: -0.5rem;
`

// max-width: 35rem;
export const SignInMessageBox = styled.div `
  padding: 1rem;
  border-radius: 5px;
  border: 1px solid ${props => props.warning ? '#b3880d' : props.error ? '#c3282d' : getColor('main')};
  background: ${props => props.warning ? '#ffe18b' : props.error ? '#c3282d' : '#bae5f7'};
  color: ${props => props.warning ? '#906c00' : props.error ? '#FFFFFF' : 'inherit'};
  font-size: 14px;
  max-width: 40rem;
  margin: auto;
  margin-bottom: 1rem;
`

export const ToggleContainer = styled.div `
  background-color: rgba(0,0,0,0.075);
  border-radius: 6px;
  padding: 1rem;
  font-weight: 500;
  margin-bottom: 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  :hover {
    background-color: rgba(0,0,0,0.09);
  }
`

export const Toggle = styled.div `
  width: 2.75rem;
  height: 18px;
  border-radius: 18px;
  background: ${props => props.checked ? getColor('main') : 'rgba(0,0,0,0.2)'};
  display: inline-block;
  position: relative;
  cursor: pointer;
  transition: 0.25s ease;
  :after {
    content: '';
    transition: 0.25s ease;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: white;
    ${props => props.checked ? css`
      left: 100%;
      margin-left: -2px;
      transform: translate(-100%,-50%);
      ` : `
      left: 0;
      margin-left: 2px;
    `}
  }
`

export const LinkSSO = styled.a`
  margin: 1.5rem auto;
  display: block;
  font-size: 13px;
  color: ${getColor('main')};
  font-weight: 600;
  text-align: center;
`

export const LocaleSelectorContainer = styled.div`
  top: 1.25rem;
  right: 1.5rem;
  position: fixed;
`


export class SignInLayout extends React.Component {

  render() {
    const {className, children, system, hideSupportMessage} = this.props
    const logo = system.systemWebHooks.systemLogoWebHook
    return (
      <SignInPageWrapper className={className}>
          <SignInPageContent>
            <SystemLogo><div><img src={logo} alt="Logo"/></div></SystemLogo>
            <LocaleSelectorContainer><LocaleSelector system={system}/></LocaleSelectorContainer>
            {children}
            {!hideSupportMessage && <SupportMessage system={system}/>}
          </SignInPageContent>
          <SignInFooter>
            <a href="https://www.purview.net/">Purview</a>
            <p><FormattedMessage
              defaultMessage="© Copyright {year}. All Rights Reserved."
              description="Powered by Purview footer"
              values={{
                year: new Date().getFullYear()
              }}
            /></p>
          </SignInFooter>
      </SignInPageWrapper>
    );
  }
}
