import _ from 'lodash/fp'
import {callIfFunction} from "./utils";

// Suspense integrations like Relay implement
// a contract like this to integrate with React.
// Real implementations can be significantly more complex.
// Don't copy-paste this into your project!
const EMPTY_ARGS = []
const UNDEFINED_CURRENT_VALUE = {}
const innerWrapLazy = (refreshCallback) => (maybePromise, ...rootArgs) => {
  let status;
  let awaitReadResolver;
  let checker = _.isEqual;
  let lazyArgs = EMPTY_ARGS;
  let uid = Math.random().toString()+Date.now();
  let result;
  let currentValue = UNDEFINED_CURRENT_VALUE

  const promisify = (maybePromise,resolveNow) => {
    return ((_.isFunction(maybePromise) && !resolveNow) ? new Promise(res => awaitReadResolver = res) : Promise.resolve())
      .then((...args) => callIfFunction(maybePromise, ...rootArgs, ...lazyArgs, ...args))
  }
  const onPromiseSuccess = (returnedPromise) => r => {
    if (returnedPromise !== suspender) {
      return suspender
    }
    status = "success";
    currentValue = r
    result = r;
    return r
  }
  const onPromiseError = (returnedPromise) => (e) => {
    if (returnedPromise !== suspender) {
      return suspender
    }
    status = "error";
    result = e;
    return Promise.reject(e)
  }

  const suspend = (maybePromise) => {
    status = "pending"
    awaitReadResolver = _.identity
    const returnedPromise = promisify(maybePromise)
    returnedPromise.then(onPromiseSuccess(returnedPromise), onPromiseError(returnedPromise));
    return returnedPromise;
  }

  let suspender = suspend(maybePromise)

  return {
    read() {
      awaitReadResolver()
      if (status === "pending") {
        throw suspender;
      } else if (status === "error") {
        throw result;
      } else if (status === "success") {
        return currentValue;
      }
    },
    promise() {
      awaitReadResolver()
      return suspender
    },
    refresh(force) {
      if (status !== "pending" || force)  {
        suspender = suspend(maybePromise)
        uid = Math.random().toString()+Date.now();
        callIfFunction(refreshCallback,this)
      }
      return this
    },
    next(...args) {
      return wrapLazy(maybePromise, ...rootArgs, ...args).checkWith(checker)
    },
    use(...args) {
      if (!checker(args, lazyArgs)) {
        if (lazyArgs !== EMPTY_ARGS) {
          uid = Math.random().toString()+Date.now();
        }
        lazyArgs = args
        suspender = suspend(maybePromise)
      }
      return this
    },
    _getCurrentValue() {
      return currentValue
    },
    silentRefresh() {
      const currentSuspender = suspender
      const suspended = promisify(maybePromise,true).then((v) => {
        if (currentSuspender === suspender) {
          suspender = suspended
        }
        return v
      })
      suspended.then(onPromiseSuccess(suspended),onPromiseError(suspended))
      uid = Math.random().toString()+Date.now();
      callIfFunction(refreshCallback,this)
      return suspended
    },
    and(lazy) {

    },
    checkWith(isEqual) {
      checker = isEqual
      return this
    },
    localUpdate(updater) {
      currentValue = callIfFunction(updater,currentValue)
      return this
    },
    uid() {
      return uid
    }
  }
}
const UNMOUNTED = {}

export const wrapLazy = innerWrapLazy()