import React from 'react'
import styled from 'styled-components'
import {SignInLayout, Card, CardHeader, CardActionButton} from '../components/SigninLayout'
import {Icon,Button} from 'startlibs/lib/components'
import {getColor} from 'startlibs/lib/lib'
import {FormattedMessage} from "react-intl";

const ExpiredLinkPage = styled(SignInLayout) `
  color: rgba(0,0,0,0.5);
  h2 {
    margin-bottom: 0.5rem;
    font-size: 20px;
    color: ${getColor('alert')};
  }
  p {
    font-size: 14px;
    max-width: 24rem;
    margin: 0 auto;
  }
  ${Icon} {
    font-size: 55px;
    margin-bottom: 0.75rem;
    display: block;
    color: rgba(0,0,0,0.3);
  }
  ${Button} {
    margin-top: 2rem;
    min-width: 10rem;
  }
`

export class LinkExpiredError extends React.PureComponent {

  render() {
    const {system} = this.props
    return <ExpiredLinkPage system={system}>
      <Card centralizedContent>
        <Icon icon="warning"/>
        <h2><FormattedMessage
          defaultMessage="This link has expired or is not valid"
          description="Expired link page title"
        /></h2>
        <p><FormattedMessage
          defaultMessage="The link you are trying to access is not valid or it has reached its expiration date."
          description="Expired link page message"
        /></p>
      </Card>
    </ExpiredLinkPage>
  }

}

export class JwtLinkExpiredError extends React.PureComponent {

  render() {
    const {system,signinUrl} = this.props
    return <ExpiredLinkPage system={system}>
      <Card centralizedContent>
          <Icon icon="warning"/>
          <h2><FormattedMessage
            defaultMessage="This link has expired or is not valid"
            description="Expired link page title"
          /></h2>
        <p><FormattedMessage
          defaultMessage="The link you are trying to access is not valid or it has reached its expiration date."
          description="Expired link page message"
        /></p>
        <Button.a href={signinUrl} highlight><FormattedMessage
          defaultMessage="Go to sign in"
          description="Go to sign in button"
        /></Button.a>
      </Card>
    </ExpiredLinkPage>
  }

}
