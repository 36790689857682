import {ice} from 'icepath'
import {AJAX_ERROR} from 'startlibs'

const erorrsOrSuccess = (success,errors) => Object.keys(errors).length ? {errors} : success

export const required = (intl) => (s, k) => !s && intl.formatMessage({
  defaultMessage: 'Required field(s).',
  description: 'Validation error message for required fields'
})

export const minLength = (intl, n) => (s, k) => s && s.length < n && intl.formatMessage({
  defaultMessage: `Field must have at least {n} characters.`,
  description: 'Validation error message for minimum length'},{
    n: n
  }
  
)

export const confirmPasswords = (intl,passwordKey) => (confirm, k, props) => (props[passwordKey] || confirm) && props[passwordKey] !== confirm && intl.formatMessage(
  {
    defaultMessage: 'Passwords do not match.',
    description: 'Validation error message for non-matching passwords'
  })
export const confirmEmails = (emailKey) => (confirm, k, props) => (props[emailKey] || confirm) && props[emailKey] !== confirm && 'Emails do not match.'

const emailRegex = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i
export const emailValidation = (intl) => (s, k) => !emailRegex.test(s) && intl.formatMessage({
  defaultMessage:'Invalid email address.',
  description: 'Validation error message for invalid email'
})

export const buildValidation = (validations,transform) => (props) =>
  erorrsOrSuccess({success: transform ? transform(props) : props},
    ice(validations)
      .map((v, k) => [].concat(v).map(fn => fn(props[k], k, props)).filter(_ => _))
      .filter((v, k) => v.length)
      .value()
  )


export const responseFailure = (form,fn) => (_,{response},n,{setErrors}) => {
  const errorSetter = fn ? form.connector.setErrors : setErrors
  if (response) {
    const error = (fn || form)(response)
    if (error) {
      errorSetter(error)
    } else {
      errorSetter({"":[AJAX_ERROR]})
    }
  }
}


export const getErrorForField = (intl) => (field) => {
  if (field === 'password') {
    return {password: []}
  }
  if (field === 'email') {
    return {email: [intl.formatMessage({
        defaultMessage: 'Invalid email address.',
        description: 'Validation error message for invalid email address'
      })]}
  }
}