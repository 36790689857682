import React, {createContext, useEffect, useMemo, useState} from 'react'
import {IntlProvider} from "react-intl";
import {wrapLazy} from "../lib/wrapLazy";
import {callIfFunction} from "../lib/utils";


const lazyLocale = wrapLazy((locale) =>
  import(`intl-compiled/${locale}.json`).then(messages => ([locale, messages]))
)

export const LocaleContext = createContext({
  locale: null,
  changeLanguage: () => {
  }
})
export const I18NProvider = ({children, defaultLocale, system}) => {
  if (!system) {
    return <IntlProvider locale="en-US">{children}</IntlProvider>
  }
  return <I18NProviderImpl defaultLocale={defaultLocale} system={system}>{children}</I18NProviderImpl>
}

const addLocaleToUrl = (locale) => {
  const params = new URLSearchParams(window.location.search)
  params.set("locale", locale)
  window.top.history.replaceState(null, null, window.location.pathname + "?" + params.toString())
}
const I18NProviderImpl = ({children, defaultLocale, system}) => {
  // Estado para controlar o idioma atual (caso o usuário altere via NavBar, por exemplo)
  const [currentLocale, setCurrentLocale] = useState(callIfFunction(defaultLocale,system) || "en-US")

  // Define o idioma padrão:
  // Se estiver em preview, usa o idioma vindo de previewCase.info.locale (ou "en-US");
  // caso contrário, se o usuário não tiver alterado, lazyLocale usará lazyProviderInfo para obter o idioma.
  const localeToLoad = currentLocale || defaultLocale

  const [messages,setMessages] = useState(() => lazyLocale.use(localeToLoad).read()[1])

  const providerValue = useMemo(() => ({
      locale:currentLocale,
      changeLanguage: (newLocale) =>
        lazyLocale.use(newLocale).promise().then(([locale, messages]) => {
          setMessages(messages)
          setCurrentLocale(locale)
        })
    })
    , [currentLocale])
  useEffect(() => {
    addLocaleToUrl(currentLocale)
  }, [currentLocale]);
  return <LocaleContext.Provider value={providerValue}>
    <IntlProvider messages={messages} locale={currentLocale} defaultLocale="en-US">
      {children}
    </IntlProvider>
  </LocaleContext.Provider>
}