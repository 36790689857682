import { Button, Loading, Icon } from 'startlibs/lib/components';
import { withRouter } from 'react-router-dom';
import {connect} from 'react-redux'
import { getFetcher, formFetch } from 'startlibs';
import {
  withForm
} from 'startlibs/lib/form';
import {withToggles} from 'startlibs/lib/hocs'
import React from 'react'
import jwt_decode from 'jwt-decode'
import styled from 'styled-components'
import {
  Card,
  CardHeader,
  SignInLayout,
  SignInMessageBox
} from '../components/SigninLayout';
import {JwtAuthenticationWithLocale} from './JwtAuthentication'
import {TwoFactorAuthentication} from './TwoFactorAuthentication'
import {buildValidation, required, responseFailure} from '../lib/validation'
import {signIn} from '../reducers'
import {getColor} from 'startlibs/lib/lib'
import {FormattedMessage, injectIntl} from "react-intl";

// max-width: ${props => props.accountSettings ? `39rem` : `35rem`};
const SignInCard = styled(Card)`
  max-width: ${props => props.accountSettings ? `42rem` : `40rem`};
  margin: auto;
  transition: 0.25s ease;
  text-align: center;
  h2 {
    font-size: 20px;
    margin-bottom: 1.5rem;
    color: ${getColor('main')};
    &.warning {
      margin-bottom: 0.5rem;
      color: ${getColor('alert')};
    }
  }
  p {
    max-width: 25rem;
    margin: 0 auto 2rem;
  }
  ${Icon} {
    font-size: 70px;
    color: ${getColor('alert')};
  }
`

const UNASSOCIATED_SYSTEM_ERROR = 'User user is not associated with a system.'
const INVALID_CREDENTIALS = (intl) => intl.formatMessage({
  defaultMessage: 'Invalid credentials',
  description: 'Login error message when credentials are invalid'
})

const refreshTimer = (time, system) => {
  
  if (time > 1) {
    return time - 1
  }

  // Redirecting...
  if(system.ssoEntities.length === 1){
    if((system.ssoEntities[0].url.length > 0) && (time === 1)){
      window.location.href = system.ssoEntities[0].url
    }
  }
  
  return 0;
}

export const RedirectLoading = styled.div`
  position: relative;
  margin: 2rem auto;
  font-size: 20px;
  font-weight: bold;
  border-radius: 50%;
  width: 52px;
  height: 52px;
  line-height: 52px;
  text-align: center;
  
`
@injectIntl
@withRouter
@withToggles('loading', 'accountSettings', 'expiredPassword', 'verify2fa','timeToRedirect','loginFailed','logout')
@withForm(formFetch)
@connect(undefined, {signIn})
export class SsoLogin extends React.Component {

  searchParams = new URLSearchParams(this.props.location.search)
  jwt = this.searchParams.has("jwt") ? jwt_decode(this.searchParams.get("jwt")) : {}


  passwordExpired = (key) => {
    this.props.verify2fa.close()
    this.props.expiredPassword.open(key)
  }

  onSuccess = (_, {idToken,id_token,username,preAuthToken}) => {
    if (username) {
      this.props.needsRegistration(idToken,username)
    } else if (preAuthToken) {
      this.props.verify2fa.open(preAuthToken)
    } else {
      this.concludeLogin(id_token)
    }
  }

  concludeLogin = (id_token) => {
    if (this.props.accountSettings.isOpen) {
      this.props.loading.open()
      getFetcher('/pasapi/account/info', null, {headers: new Headers({'Authorization': `Bearer ${id_token}`})})
        .then((result) => {
          this.props.signIn({...result, id_token})
          this.props.history.push('/account?systemId='+this.props.system.systemId)
        })
    } else {
      this.props.loading.open()
      window.location = this.props.system.systemWebHooks.systemAuthWebHook + id_token + "&locale="+this.props.intl.locale
    }
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(this.props.location.search)
    // if(this.props.system.systemWebHooks && this.props.system.systemWebHooks.migratedUrl){
    //   window.location.href = this.props.system.systemWebHooks.migratedUrl+this.props.location.pathname+this.props.location.search;
    // }
    if (urlParams.get("loginFailure")==='true') {
      this.props.loginFailed.open(true)
    }else{
      if (urlParams.get("logout")==='true') {
        this.props.logout.open(true)
      }else{    
        this.props.timeToRedirect.open(5)
        setInterval(() => {
          this.props.timeToRedirect.open(refreshTimer(this.props.timeToRedirect.isOpen, this.props.system));
        }, 1000);
      }
    }
    if (urlParams.get("username")) {
      this.props.form.utils.setValue('username',urlParams.get("username"))
    }
    if (urlParams.get("preAuthToken")) {
      this.props.verify2fa.open(urlParams.get("preAuthToken"))
    }
    this.props.form.utils.setValue('systemId',this.props.system.systemId)
    if (this.props.loginToSettings) {
      this.props.accountSettings.open()
    }
    
    
  }


  render() {
    const {intl, system, form, loginFailed, logout, fromActivation, accountSettings, timeToRedirect, expiredPassword, verify2fa, needsRegistration, location} = this.props
    const expiredSession = location.state && location.state.expiredSession

    if (verify2fa.isOpen) {
      return <TwoFactorAuthentication
        system={system}
        preAuthToken={verify2fa.isOpen}
        concludeLogin={this.concludeLogin}
        passwordExpired={this.passwordExpired}
        username={form.properties.username}
      />
    }

    if (this.jwt.sub && !expiredPassword.isOpen) {
      return <JwtAuthenticationWithLocale
        passwordExpired={this.passwordExpired}
        needsRegistration={needsRegistration}
        system={system}
        verify2fa={verify2fa}
        parsedJwt={this.jwt}
        jwt={this.searchParams.get("jwt")}
      />
    }

    return (
      <SignInLayout hideSupportMessage system={system}>
        {
          fromActivation &&
          <SignInMessageBox><FormattedMessage
            defaultMessage="Email address successfully updated."
            description="Email address successfully updated message"
          />
          </SignInMessageBox>
        }
        {
          expiredSession &&
          <SignInMessageBox warning><FormattedMessage
            defaultMessage="Your session has expired. Please sign in again."
            description="Session expired message"
          />
          </SignInMessageBox>
        }

        {loginFailed.isOpen && <SignInCard accountSettings={accountSettings.isOpen}>
          {/* Only one sso provider */}
          <Icon icon="warning"/>
          <h2 className="warning"><FormattedMessage
            defaultMessage='This account has been suspended'
            description='Login error message when account is suspended'
          /></h2>
          <p><FormattedMessage
            defaultMessage='Please contact your administrator'
            description='Login error message when account is suspended, instructions'
          /></p>
          {system.ssoEntities.length === 1 &&  
            <>
              {system.ssoEntities.map((sso, index) => <Button.a key={index} highlight href={sso.url} css="min-width:12rem;"><FormattedMessage
                defaultMessage="Try again"
                description="Login error message, try again button"
              /></Button.a>)}
            </>
          }

          {/* More than one sso provider */}
          {system.ssoEntities.length > 1 && <>
            <CardHeader>
              <p><FormattedMessage
                defaultMessage="Maybe you can try using another Single Sign On provider"
                description="Login error message, try another SSO provider"
              /></p>
            </CardHeader>
            {system.ssoEntities.map((sso, index) => 
              <div css="margin: 12px;" key={index}>
                <Button.a highlight href={sso.url}>{sso.label || intl.formatMessage({
                  defaultMessage:'Use Single Sign On',
                  description:'Login error message, use SSO button'
                })}</Button.a>
              </div>)
            }
            {system.ssoEntities.map((sso, index) => 
              <div css="margin: 12px;" key={index}>
                <Button.a highlight href={sso.url}>{sso.label || intl.formatMessage({
                  defaultMessage:'Use Single Sign On',
                  description:'Login error message, use SSO button'
                })}</Button.a>
              </div>)
            }
            </>
          }
          </SignInCard>
        }

        {logout.isOpen && <SignInCard accountSettings={accountSettings.isOpen}>
          <h2><FormattedMessage
            defaultMessage="You have been logged out"
            description="Logout message"
          /></h2>
          {/* <button href="/sso">Login</button> */}
          <Button.a highlight href={`/sso?systemId=${this.props.system.systemId}&locale=${intl.locale}`}><FormattedMessage
            defaultMessage="Sign in Again"
            description="Logout message, sign in again button"
          /></Button.a>
          
        </SignInCard>
        }
        
        {!loginFailed.isOpen && !logout.isOpen && <SignInCard accountSettings={accountSettings.isOpen}>
          {/* Only one sso provider */}
          {system.ssoEntities.length === 1 && 
            <>
              <CardHeader>
                <h1><FormattedMessage
                  defaultMessage="Loading {systemName}"
                  description="Loading system message"
                  values={{systemName: system.systemName}}
                /></h1>
                <p><FormattedMessage
                  defaultMessage="You will be redirected to {systemName} authentication page"
                  description="Loading system instructions"
                  values={{systemName: system.ssoEntities[0].label || system.systemName}}
                /></p>
              </CardHeader>
              <RedirectLoading>
                <Loading size={52} borderWidth={5} absolute/>
                <div>{timeToRedirect.isOpen}</div>
              </RedirectLoading>

              {system.ssoEntities.map((sso, index) => <Button.a key={index} highlight href={sso.url} css="min-width:12rem;"><FormattedMessage
                defaultMessage="Take me now"
                description="Loading system, take me now button"
              /></Button.a>)}
            </>
          }

          {/* More than one sso provider */}
          {system.ssoEntities.length > 1 && <>
            <CardHeader>
              <h1>{system.systemName}</h1>
              <h3><FormattedMessage
                defaultMessage="Select your {systemName} authentication page"
                description="Select SSO provider message"
                values={{systemName: system.systemName}}
              /></h3>
            </CardHeader>
            {system.ssoEntities.map((sso, index) => 
              <div css="margin: 12px;" key={index}>
                <Button.a highlight href={sso.url}>{sso.label || intl.formatMessage({
                  defaultMessage:'Use Single Sign On',
                  description:'Select SSO provider button'
                })}</Button.a>
              </div>)
            }
            </>
          }
        </SignInCard>}

        
        {/* <SupportMessage system={system}/> */}
        {/* {!expiredPassword.isOpen && !loginToSettings &&
          <AdditionalActionButtons>
            <Button outline small onClick={accountSettings.toggle}>{accountSettings.isOpen ?
              <span>Back to sign in</span> : 'Authentication settings'}</Button>
          </AdditionalActionButtons>
        } */}
      </SignInLayout>
    )
  }
}
