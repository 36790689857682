import React from 'react'
import {Button, FlipControl, Icon} from 'startlibs/lib/components'
import {
  WithForm,
  withEditableBox,
  EditableBox,
  Errors,
  Field,
  Form,
  TextInput,
  SimpleCheckbox
} from 'startlibs/lib/form'
import {getFetcher, formFetch} from 'startlibs'
import {withToggles} from 'startlibs/lib/hocs'
import {formFetchWithAuthorization, logout, set2FA, setEmail, setName, signIn} from '../reducers'
import {buildValidation, confirmPasswords, emailValidation, required, responseFailure} from '../lib/validation'
import {withRouter} from 'react-router-dom'
import {connect} from 'react-redux'
import {
  SignInLayout,
  Card,
  CardHeader,
  AdditionalActionButtons,
  FieldRequirements,
  Toggle,
  ToggleContainer
} from '../components/SigninLayout'
import styled from 'styled-components'
import {PasswordInput} from '../components/PasswordInput'
import {SupportMessage} from '../components/SupportMessage'
import {FormattedMessage, injectIntl} from "react-intl";

const LockedIcon = styled(Icon)`
  user-select: none;
  position: absolute;
  outline: none;
  bottom: 0;
  font-size: 21px;
  line-height: 3rem;
  right: 0;
  width: 2rem;
  pointer-events: none;
  color: #969696;
`

const DEFAULT_FORM = {rememberMe: false}

@injectIntl
@withRouter
@withToggles('accountSettings', 'visiblePassword')
@withEditableBox()
@connect(({user}) => ({user}), {signIn, logout})
export class AccountSettings extends React.Component {

  logout = () => {
    this.props.logout()
    this.props.history.push(`/?systemId=${this.props.system.systemId}&username=${this.props.user.username}`,{loginKey:Date.now(),expiredSession:true})
  }

  onFailure = (...args) => {
    const {response} = args[1]
    if (response.status === 401) {
      this.logout()
    } else {
      const incorretPassword = this.props.intl.formatMessage({
        defaultMessage:'Incorrect password',
        description:'Account settings error, incorrect password'
      })
      responseFailure(
        ({detail, title, type, message}) =>
          (title === 'Incorrect password' && {password: [incorretPassword + '.'], currentPassword: [incorretPassword + '.']}) ||
          (title === 'passwordCannotBeEqualToThePreviousPasswords' && {newPassword: [
              this.props.intl.formatMessage({
                defaultMessage: `Your new password must be different from your previous {pwdInHistory} passwords.`,
                description: 'Account settings error, new password must be different from previous'
              },{pwdInHistory:this.props.system.systemAuthPolicy.pwdInHistory}
            )]}) ||
          (type === 'https://pas.purview.net/problem/invalid-password' && {newPassword: []}) ||
          (message === 'error.canNotChange2FA' && {enabled: [this.props.intl.formatMessage({
              description:'System has 2FA enabled for all users',
              defaultMessage:'2FA is enabled for all users by the administrator'
            })]})
      )(...args)
    }
  }


  render() {
    const {system, user, logout, visiblePassword, intl} = this.props

    const emailPreValidation = buildValidation({
      password: [required(intl)],
      email: [required(intl), emailValidation(intl)]
    })

    const using2FAValidation = buildValidation({
      password: [required(intl)]
    })

    const passwordPreValidation = buildValidation({
      currentPassword: [required(intl)],
      newPassword: [required(intl), confirmPasswords(intl,'confirmNewPassword')]
    })

    const namePreValidation = buildValidation({
      firstName: [required(intl)],
      lastName: [required(intl)]
    })
    return (
      <SignInLayout hideSupportMessage system={system}>
        <Card>
          <CardHeader>
            <h1><FormattedMessage
              defaultMessage="User authentication settings"
              description="Account settings page title"
            /></h1>
          </CardHeader>
          <Field label={intl.formatMessage({
              defaultMessage: "Name",
              description: "Account settings name field label"
            })}>
            <WithForm action={setName} id_token={user.id_token} url="/pasapi/account" values={user}
                      preValidation={namePreValidation} onFailure={this.onFailure}>
              {() =>
                <EditableBox
                  path={[]}
                  view={({getValue}) => getValue('firstName') + ' ' + getValue('lastName')}
                  edit={() =>
                    <>
                      <TextInput
                        mandatory
                        path="firstName"
                        label={intl.formatMessage({
                          defaultMessage: "First name",
                          description: "Account settings first name input label"
                        })}
                      />
                      <TextInput
                        mandatory
                        path="lastName"
                        label={intl.formatMessage({
                          defaultMessage: "Last name",
                          description: "Account settings last name input label"
                        })}
                      />
                    </>
                  }
                />
              }
            </WithForm>
          </Field>
          <Field label={intl.formatMessage({
              defaultMessage: "Email",
            description: "Account settings email field label"
            })}>
            <WithForm action={setEmail} id_token={user.id_token} locale={intl.locale} values={{}} preValidation={emailPreValidation}
                      onFailure={this.onFailure}>
              {() =>
                <EditableBox
                  path={[]}
                  edit={() =>
                    <>
                      <TextInput
                        mandatory
                        path="email"
                        label={intl.formatMessage({
                          defaultMessage: "New email",
                          description: "Account settings new email input label"
                        })}
                        descText={<span><FormattedMessage
                          defaultMessage="Your current email is: <b>{email}</b>"
                          description="Account settings current email input description"
                          values={{
                            email: user.email,
                            b: (chunks) => <b>{chunks}</b>
                          }}
                        /></span>}
                      />
                      <PasswordInput
                        path="password"
                        mandatory
                        label={intl.formatMessage({
                          defaultMessage: "Account password",
                          description: "Account settings password input label"
                        })}
                        descText={intl.formatMessage({
                          defaultMessage: "For security reasons you need to enter your account password to change the email address.",
                          description: "Account settings password input description"
                        })}
                      />
                      <span className="field-description"><FormattedMessage
                        defaultMessage="In order for this change to take effect, you must confirm your email from a link that will be sent to the new registered address."
                        description="Account settings email change description"
                      /></span>
                    </>
                  }
                  view={() => user.email}
                />
              }
            </WithForm>
            {user.newEmail &&
            <span className="post-field-description"><FormattedMessage
              defaultMessage="Email change requested to <b>{newEmail}</b>. Click on the link sent to this address to effect the change."
              description="Account settings email change requested description"
              values={{
                newEmail: user.newEmail,
                b: (chunks) => <b>{chunks}</b>
              }}
            /></span>}
          </Field>
          <Field label={intl.formatMessage({
              defaultMessage: "Username",
              description: "Account settings username field label"
            })}>
            <div className="editable-box collapsed locked">
              {user.username}
              <LockedIcon icon="lock"/>
            </div>
          </Field>
          <Field label={intl.formatMessage({
              defaultMessage: "Password",
              description: "Account settings password field label"
            })}>
            <WithForm action={formFetchWithAuthorization} preValidation={passwordPreValidation}
                      url={`/pasapi/account/change-password?locale=${intl.locale}`} id_token={user.id_token} onFailure={this.onFailure}>
              {(form) =>
                <EditableBox
                  view={() => <i><FormattedMessage
                    defaultMessage="Click to change your password"
                    description="Account settings change password button"
                  /></i>}
                  edit={() =>
                    <>
                      <PasswordInput
                        mandatory
                        path="currentPassword"
                        label={intl.formatMessage({
                          defaultMessage: "Current password",
                          description: "Account settings current password input label"
                        })}
                      />
                      <PasswordInput
                        mandatory
                        path="newPassword"
                        syncVisible={visiblePassword}
                        label={intl.formatMessage({
                          defaultMessage: "New password",
                          description: "Account settings new password input label"
                        })}
                      />
                      <PasswordInput
                        mandatory
                        path="confirmNewPassword"
                        syncVisible={visiblePassword}
                        label={intl.formatMessage({
                          defaultMessage: "Confirm new password",
                          description: "Account settings confirm new password input label"
                        })}
                      />
                      <FieldRequirements hasErrors={!!form.errors['newPassword']}>
                        <FormattedMessage
                          defaultMessage={`Your new password must be <b>{pwdMinimumLength} or more characters</b>,
                        containing at least <b>one uppercase letter</b>, one <b>number</b> and one <b>special
                        character</b>.`}
                          description="Account settings new password requirements"
                          values={{
                            pwdMinimumLength: system.systemAuthPolicy.pwdMinimumLength,
                            b: (chunks) => <b>{chunks}</b>
                          }}
                        />
                      </FieldRequirements>
                    </>
                  }
                />
              }
            </WithForm>
          </Field>
          <Field label={intl.formatMessage({
              defaultMessage: "Two-Factor Authentication",
              description: "Account settings 2FA field label"
            })}>
            {
              system.using2FA
              ? <div className="editable-box collapsed locked">
                <i><FormattedMessage
                  defaultMessage="Enabled for all users by the administrator"
                  description="Account settings 2FA enabled description"
                /></i>
                <LockedIcon icon="lock"/>
              </div>
              : <WithForm action={set2FA} id_token={user.id_token} locale={intl.locale}
                  values={{enabled: user.using2FA}}
                          preValidation={using2FAValidation}
                          onFailure={this.onFailure}>
                {(form) =>
                  <EditableBox
                    path={[]}
                    edit={(v) =>
                      <>
                        <p><FormattedMessage
                          defaultMessage="After you log in with your password, you’ll be asked for a temporary code sent to your email."
                          description="Account settings 2FA description"
                        /></p>
                        <ToggleContainer onClick={() => form.utils.setValue('enabled', !form.properties.enabled)}>
                          <><b><FormattedMessage
                            defaultMessage="Enabled"
                            description="Account settings 2FA enabled label"
                          /></b><Toggle checked={form.properties.enabled}/></>
                        </ToggleContainer>
                        <PasswordInput
                          path="password"
                          mandatory
                          label={intl.formatMessage({
                            defaultMessage: "Account password",
                            description: "Account settings password input label"
                          })}
                          descText={intl.formatMessage({
                            defaultMessage: "For security reasons you need to enter your account password to change this option.",
                            description: "Account settings password input description"
                          })}
                        />
                      </>
                    }
                    view={() =>
                      (user.using2FA && 'Enabled') ||
                      <i><FormattedMessage
                        defaultMessage="Click to set up extra security with two-factor authentication"
                        description="Account settings 2FA setup button"
                      /></i>
                    }
                  />
                }
              </WithForm>
            }

          </Field>
        </Card>
        <SupportMessage system={system}/>
        <AdditionalActionButtons>
          {/*<Button outline small onClick={logout}>Sign out</Button>*/}
          <Button.a small highlight href={system.systemWebHooks.systemAuthWebHook + user.id_token}>
            {system.labels.systemGoTo || <FormattedMessage
              defaultMessage="Go to case list"
              description="Account settings go to case list button"
            />}
          </Button.a>
        </AdditionalActionButtons>
      </SignInLayout>
    )
  }
}
