import React, {useContext, useState} from 'react'
import _ from 'lodash/fp'
import {LocaleContext} from "./I18NProvider";
import styled from 'styled-components'
import {getDefaultLanguageInfo, getLanguageInfo} from "../lib/getInitLocale";
import {connect} from "react-redux";
import {openPopup} from "startlibs";
import {Loading} from "startlibs/lib/components";
import {getColor} from 'startlibs/lib/lib'
import {DropdownIcon} from 'startlibs/lib/components'

const LocaleButton = styled.a`
  position: relative;
  display: flex;
  align-self: center;
  align-items: center;
  border: 1px solid ${getColor('gray210')};
  padding: 0.5rem 1.75rem 0.5rem 1rem;
  border-radius: 100px;
  font-weight: 600;
  font-size: 13px;
  color: ${getColor('gray90')};
  flex-shrink: 0;
  :hover {
    color: ${getColor('gray90')};
    background: ${getColor('gray240')};
  }
  :active {
    color: ${getColor('gray90')};
    background: ${getColor('gray230')};
  }
  ${DropdownIcon} {
    right: 0.75rem;
    font-size: 10px;
  }
`

export const ComboboxOptionDiv = styled.div`
  display: flex; 
  align-content: center;  
  align-items: center; 
  justifyContent: flex-start; 
`
const LocaleFlag = styled.div`
  margin-right:0.5rem;
  border-radius: 2px !important;
`
const ContextMenu = styled.div`
  position: absolute;
  z-index: 999;
  background-color: white;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.5);
  border-radius: 6px;
  top: 2.6rem;
  right: 0;
  padding: 0.5rem 0;
  overflow: hidden;
  ${LocaleFlag} {
    margin-right:0.75rem;
  }
`
const ListItem = styled.div`
  position: relative;
  font-size: 13px;
  white-space: nowrap;
  font-weight: 400;
  & > div {
    padding: 11px 1.5rem;
    white-space: nowrap;
    :hover {
      cursor: pointer;
      background-color: ${getColor('main')};
      color: white;
    }
  }
`

const LocaleIcon = ({info}) => <LocaleFlag className={`iti__flag ${info.symbol}`}/>
export const LocaleOption = ({locale, system, onClick}) => {
  const languageInfo = getLanguageInfo(locale, system)
  if (!languageInfo) return null
  return <ListItem><ComboboxOptionDiv onClick={onClick}>
    <LocaleIcon info={languageInfo}/>
    {languageInfo.caption}
  </ComboboxOptionDiv></ListItem>
}

export const LocaleSelector = connect(({appUI}) => ({isPopupOpen: appUI.openPopup && appUI.openPopup["locale-menu"]}), {openPopup /*getAccount*/})(
  ({
     openPopup, isPopupOpen, system
   }) => {
    const [isLoading, setLoading] = useState()

    const {locale, changeLanguage} = useContext(LocaleContext)

    const handleLanguageToggle = (newLocale) => {
      setLoading(true)
      changeLanguage(newLocale).finally(() => setLoading(false))
    }
    const languageInfo = getLanguageInfo(locale, system) || getDefaultLanguageInfo(system)

    if (!languageInfo || _.get('systemWebHooks.preferredLanguage.options.length', system) < 2) {
      return null
    }

    return <LocaleButton onClick={() => openPopup("locale-menu",{value:true})}>
      <LocaleIcon info={languageInfo}/>
      {isLoading && <Loading/>}
      {languageInfo.hint}
      <DropdownIcon icon="arrow-down"/>
      {
        isPopupOpen &&
        <ContextMenu>
          {system.systemWebHooks.preferredLanguage.options.map(option =>
            <LocaleOption
              key={option.id}
              locale={option.id}
              system={system}
              withoutIcons
              onClick={() => handleLanguageToggle(option.id)}
            />
          )}
        </ContextMenu>
      }
    </LocaleButton>
  })